:root {
  /* --black: #2b2d42;
  --lighter-black: #5f5e5e;
  --white: #ffffff;
  --grey: #d9d9d9;
  --navy: #284b63;
  --green: #3c6e71;
  --salmon: #c16e70; */
  --black: #2b2d42;
  --grey: #8d99ae;
  --white: #edf2f4;
  --light-red: #23bfef;
  --dark-red: #d90429;
  --project-button: #456990;
  --project-headline: #2a4158;
  --orange-download-orange: #fca311;
  --image-background: #d6dee2;
  --purple-button: #7209b7;
  --blue-button: #4361ee;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #909cb0;
}

body {
  font-family: 'Helvetica Neue', sans-serif;
}

h1 {
  color: var(--black);
}

.header-title {
  width: 15rem;
}

.logo {
  line-height: 60px;
  float: left;
  margin: 16px;
}

.home {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(./images/pattern.svg) no-repeat center;
  background-size: cover;
}

nav {
  position: fixed;
  width: 100%;
  line-height: 60px;
}

nav ul {
  line-height: 60px;
  list-style: none;
  padding: 0;
  text-align: right;
  margin: 0;
  transition: 1s;
}

nav ul li {
  display: inline-block;
  padding: 16px 40px;
}

nav ul li a {
  text-decoration: none;
  font-weight: 600;
  color: var(--project-button);
}

.social-icons {
  display: flex;
  align-items: center;
  animation: social-icons-swing 0.5s linear;
}

.linux-image {
  width: 48px;
  height: auto;
}

.download-button {
  background-color: var(--orange-download-orange);
  /* background-color: #2d93ad; */
}

.download-button:hover {
  transition: 0.7s ease;
  background-color: #2a4158 !important;
}
.content {
  font-size: 20px;
  text-align: justify;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Set a fixed height, adjust as needed */
}

.title-name {
  color: var(--grey);
  /* border-bottom: 10px solid var(--orange-download-orange); */
}

.catch-phrase {
  font-size: xx-large;
  color: var(--black);
}
.orange-cursor {
  color: orange;
}

.titles h1 {
  font-size: 100px;
}

.title-name {
  font-size: 75px;
}
.homepage-icon {
  width: 48px;
}
.orange-dot {
  color: orange;
}

.homepage-img {
  width: 25rem;
  margin-right: 0;
  border-radius: 50%;
}

.main-home {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 16rem;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.about-content h1 {
  color: var(--black);
}
.about-paragraph-container {
  width: 65%;
}

.about {
  display: flex;

  padding-top: 1rem;
  background-color: var(--white);
}

.education-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 
.bcit-logo {
  height: 5rem;
  margin-top: 2rem;
}
.bcit-title {
  margin-left: 15px;
}

.bcit-info {
} */
.education, .certificates {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
}

.education h3, .certificates h3 {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

/* Education container */
.education-container, .certificates-container {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  margin-bottom: 15px; /* Adds space between sections */
}

/* BCIT and AWS logo styling */
.bcit-logo, .aws-logo {
  width: 80px;
  height: auto;
  border-radius: 5px;
  object-fit: contain;
}

.bcit-info, .cert-info {
  display: flex;
  flex-direction: column;
}

.bcit-info h4, .cert-info h4 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #444;
}

.bcit-title {
  font-size: 18px;
  font-weight: 400;
  color: #666;
}

/* Certificate button styling */
.cert-button {
  margin-top: 10px;
}

.cert-button a {
  display: inline-block;
  padding: 10px 15px;
  background-color: #0073e6; /* A shade of blue */
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s;
}

.cert-button a:hover {
  background-color: #005bb5; /* Darker blue on hover */
}
.projects-content {
  text-align: center;
}

.technical-skills-title {
  margin-top: 10rem;
}

.contact-container {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 20px;
  height: 100vh;
}

.about-paragraph {
  color: var(--grey);
}

.title {
  color: var(--black);
}
.title-contact {
  display: flex;
  justify-content: center;
}
.contact-message {
  display: flex;
  justify-content: center;
}

.contact {
  height: 100vh;
}

.title-contact {
  color: var(--black);
}

.contact-button {
  background-color: var(--navy);
  border: none;
}

.timeline img {
  border: 0;
  width: 50vh;
  border-color: #5f5e5e;
}

input {
  background-color: white;
  width: 70%;
  margin-left: 12%;
  font-size: 10px;
  padding: 16px 16px;
  border-radius: 10px;
  border-color: black;
  margin-bottom: 30px;
}

textarea {
  background-color: white;
  width: 70%;
  margin-left: 12%;
  font-size: 20px;
  padding: 16px 16px;
  border-radius: 10px;
  border-color: black;
  margin-bottom: 25px;
}

input:focus-within {
  outline: none;
  border-color: #5bd183;
  border: width 3px;
}

textarea:focus-within {
  outline: none;
  border-color: #5bd183;
  border: width 3px;
}

.contact-form-button {
  background-color: #5bd183;
  border-radius: 10px;
  font-size: 22px;
  padding: 10px 18px;
  text-align: center;
  display: flex;
  margin: auto;
  border: none;
}

button:hover {
  cursor: pointer;
}

.dvs-header__trigger {
  display: none;
}

.skills {
  display: flex;
  flex-direction: row;
}

.skills div {
  margin: 10px 10px 10px 10px;
}

.helm-svg {
  width: 50px;
}

.projects {
  padding-top: 1rem;
  background: url(./images/pattern-background.svg) no-repeat center;
  background-size: cover;
}

.project-container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
}

.project {
  margin: 15px;
  margin-bottom: 25rem;
}
.project-headline {
  color: var(--project-headline);
}

.project-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.project-powered-by {
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  border-left: 10px solid var(--orange-download-orange);
  margin-left: 50px;
  padding-left: 20px;
}

.project-image img {
  width: 40rem;
  max-width: 100%;
  height: auto;
}

.project-image p {
  color: var(--project-headline);
  font-weight: 500;
  font-size: large;
}

.tech-stack {
  display: flex;
  align-items: center;
}

.tech-stack img {
  width: 40px;
}

.project-source-live-buttons button {
  width: 10rem;
}

.view-sourcecode-button {
  margin-top: 20px;
  width: 5rem;
  height: 2.5rem;
  border: none;
  border-radius: 5px;
  background-color: var(--project-headline);
  color: var(--white);
  font-weight: 500;
}

.view-sourcecode-button {
  background-color: var(--blue-button);
}

.view-sourcecode-button a {
  text-decoration: none;
}

.view-live-button {
  margin-right: 10px;
  background-color: var(--purple-button);
  margin-top: 20px;
  width: 5rem;
  height: 2.5rem;
  border: none;
  border-radius: 5px;
  color: var(--white);
  font-weight: 500;
}
.view-live-button a {
  text-decoration: none;
}

@media (max-width: 800px) {
  .dvs-header__menuItems {
    display: none;
  }

  .burger-stack {
    width: 2rem;
    margin-right: 20px;
  }

  .dvs-header__menuItems.is-open {
    display: flex;
    flex-direction: column;
    align-items: last baseline;
    list-style: none;
    justify-content: space-between;
    padding: 0;
    margin: 0;
  }

  nav ul li {
    display: inline-block;
    padding: 0px 20px;
    font-weight: 300;
  }

  .dvs-header__trigger {
    display: block;
  }

  .content {
    margin: 0;
  }

  .main-home {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 10rem;
  }

  .titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .titles h1 {
    font-size: 40px;
  }
  .ivan-picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .homepage-img {
    width: 15rem;
    margin-right: 0;
    border-radius: 50%;
  }

  .title {
    font-size: xx-large;
    color: var(--black);
  }

  .about-paragraph {
    font-size: medium;
  }

  .title-name {
    font-size: xx-large;
  }

  .catch-phrase {
    font-size: small;
  }

  .skills {
    flex-direction: column;
  }

  .project-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .projects {
    height: auto;
  }
  .project {
    margin-bottom: 50px;
  }

  .about {
    display: flex;
    height: max-content;
    padding-top: 1rem;
    background-color: var(--white);
  }

  .project-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .project-powered-by {
    max-width: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    border-left: 0px;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 0px;
  }

  .view-live-button {
    margin-right: 5px;
    background-color: var(--purple-button);
    margin-top: 20px;
    width: 5rem;
    height: 2.5rem;
    border: none;
    border-radius: 5px;
    color: var(--white);
    font-weight: 500;
  }

  .project {
    margin-bottom: 15rem;
  }
}

@keyframes drop-image {
  from {
    opacity: 0;
    transform: translateY(-500px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes swing-image {
  from {
    opacity: 0;
    transform: translateX(-500px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes social-icons-swing {
  from {
    opacity: 0;
    transform: translateX(1000px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
